import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const Language = atom({
    key: 'Language',
    default: 'en',
    effects_UNSTABLE: [persistAtom],
});

export default Language;