import { styled } from "styled-components";
import TopArrow from "../../pages/assets/images/TopArrow";

const TopMoveButton = () => {

    const moveToDocumentTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return (
        <Container onClick={moveToDocumentTop}>
            <TopArrow />
        </Container>
    )
};

export default TopMoveButton;

const Container = styled.div`

    position: fixed;

    width: 36px;
    height: 36px;
    
    bottom: 20px;
    right: 20px;
    background-color: #59c6f8;
    border-radius: 5px;
    padding: 8px;
    box-sizing: border-box;
    
    &:hover {
        background-color: #60A5FA;
        cursor: pointer;
    }

    @media screen and (max-width: 1024px) {

        width: 24px;
        height: 24px;
        padding: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 60%;
        }
    }
`;