
const EmptyCheckBoxIconSvg = () => {
    return (
        <svg
            className="empty_check_box_icon"
            fill="white"
            viewBox="0 -0.5 25 25"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="m24 24h-24v-24h24.8v24zm-1.6-2.4v-19.2h-20v19.2z">
            </path>
        </svg>
    )
}

export default EmptyCheckBoxIconSvg;