import React, { useEffect, useState } from "react";
import { styled, css, keyframes } from "styled-components";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import CheckIconSvg from "./assets/images/CheckIconSvg";
import { useMutation, useQuery } from "react-query";
import {
  approvePayment,
  getMyInfo,
  loginCheck,
  options,
  paymentGetUrl,
  paymentStatusCheck,
  subscriptionListStatus,
} from "../api/RestApi";
import { useLocation, useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { useRecoilState } from "recoil";
import Language from "../recoil/Language";
import Lang from "../pages/lang/Lang.json";
import { QueryStringArray } from "../util/QueryString";

const PaypalOptions = {
  "client-id":
    "AetJMO5QlMBr892OyVh490mR4SF2E4UJaT3JFLcOKxQOSFK8khmhRUUwz-PkYIq51FrY9RJSXg1ARUy6", //"AZEQSHMIDGLrM8lo6F0AMEhIuTzSvwZIj8cbWEt_c_JkCoThhzmivI6JBfTRGE9ae8li0KX8gOAQixU_",
  "enable-funding": "paylater,card",
  "disable-funding": "",
  "data-sdk-integration-source": "integrationbuilder_sc",
  vault: "true",
  intent: "subscription",
};

const PaidPlanID = "P-2C312864U9801041WMMRPEHY";
const SpecialPlanID = "P-0WV980426R476701UMMRPLJA";

const Pricing = () => {
  const location = useLocation();
  const query_string = location.search;
  const navigator = useNavigate();
  const [isLogin, setLogin] = useState(false);
  const [lang] = useRecoilState(Language);

  // 페이팔 상품 ID
  const [purchaseProductId, setPurchasedProductId] = useState("");

  // 구독 ID
  const [subscriptId, setSubscriptId] = useState("");

  // 구독 상태
  const [isPurchased, setPurchasedStatus] = useState(false);

  // 결제 모드
  const [mode, setMode] = useState(0);

  const cookie = new Cookies();

  const [products] = useState(["free", PaidPlanID, SpecialPlanID]);

  const [words, setWords] = useState({
    free: {
      heaer1: Lang[lang]["price"]["price_freeTitle"],
      heaer2: Lang[lang]["price"]["price_freeType"],
      heaer3: Lang[lang]["price"]["price_freePrice"],
      contents: [
        Lang[lang]["price"]["price_freeText1"],
        Lang[lang]["price"]["price_freeText2"],
        Lang[lang]["price"]["price_freeText3"],
      ],
    },
    [PaidPlanID]: {
      heaer1: Lang[lang]["price"]["price_paidTitle"],
      heaer2: Lang[lang]["price"]["price_paidType"],
      heaer3: Lang[lang]["price"]["price_paidPrice"],
      contents: [
        Lang[lang]["price"]["price_paidText1"],
        Lang[lang]["price"]["price_paidText2"],
        Lang[lang]["price"]["price_paidText3"],
        Lang[lang]["price"]["price_paidText4"],
        Lang[lang]["price"]["price_paidText5"],
        Lang[lang]["price"]["price_paidText6"],
        Lang[lang]["price"]["price_paidText7"],
      ],
    },
    [SpecialPlanID]: {
      heaer1: Lang[lang]["price"]["price_specialTitle"],
      heaer2: Lang[lang]["price"]["price_specialType"],
      heaer3: Lang[lang]["price"]["price_specialPrice"],
      contents: [
        Lang[lang]["price"]["price_specialText1"],
        Lang[lang]["price"]["price_specialText2"],
        Lang[lang]["price"]["price_specialText3"],
        Lang[lang]["price"]["price_specialText4"],
        Lang[lang]["price"]["price_specialText5"],
        Lang[lang]["price"]["price_specialText6"],
        Lang[lang]["price"]["price_specialText7"],
        Lang[lang]["price"]["price_specialText8"],
      ],
    },
  });

  // =============================================================================

  useEffect(() => {
    document.documentElement.scrollTop = 0;

    // ==============================
    // 언어 변경 시 재렌더링 처리
    setWords({
      free: {
        heaer1: Lang[lang]["price"]["price_freeTitle"],
        heaer2: Lang[lang]["price"]["price_freeType"],
        heaer3: Lang[lang]["price"]["price_freePrice"],
        contents: [
          Lang[lang]["price"]["price_freeText1"],
          Lang[lang]["price"]["price_freeText2"],
          Lang[lang]["price"]["price_freeText3"],
        ],
      },
      [PaidPlanID]: {
        heaer1: Lang[lang]["price"]["price_paidTitle"],
        heaer2: Lang[lang]["price"]["price_paidType"],
        heaer3: Lang[lang]["price"]["price_paidPrice"],
        contents: [
          Lang[lang]["price"]["price_paidText1"],
          Lang[lang]["price"]["price_paidText2"],
          Lang[lang]["price"]["price_paidText3"],
          Lang[lang]["price"]["price_paidText4"],
          Lang[lang]["price"]["price_paidText5"],
          Lang[lang]["price"]["price_paidText6"],
          Lang[lang]["price"]["price_paidText7"],
        ],
      },
      [SpecialPlanID]: {
        heaer1: Lang[lang]["price"]["price_specialTitle"],
        heaer2: Lang[lang]["price"]["price_specialType"],
        heaer3: Lang[lang]["price"]["price_specialPrice"],
        contents: [
          Lang[lang]["price"]["price_specialText1"],
          Lang[lang]["price"]["price_specialText2"],
          Lang[lang]["price"]["price_specialText3"],
          Lang[lang]["price"]["price_specialText4"],
          Lang[lang]["price"]["price_specialText5"],
          Lang[lang]["price"]["price_specialText6"],
          Lang[lang]["price"]["price_specialText7"],
          Lang[lang]["price"]["price_specialText8"],
        ],
      },
    });
    // ==============================

    // ==============================
    // init function
    mutate();
    paymentModeCheck();
    userPurchasedProductCheck();
    // ==============================

    // ==============================
    const query = QueryStringArray(query_string);
    const oid = window.localStorage.getItem("sundi.payment.oid");
    // ==============================

    // ============================================================
    // paypal 결제 페이지 이동 후 결제 완료 또는 실패 시, 조건 처리
    if (oid) {
      console.log("check. order success");
      if (query["status"] === "ok") {
        console.log("check. purchased ok");
        let formData = new FormData();
        formData.append("oid", oid);
        console.log("check. purchased status");
        const checkResult = paymentStatusCheck(formData);

        checkResult.then((data) => {
          console.log("check. purchased status...");
          const res = data.data;
          if (res.success) {
            if (res.data.check) {
              alert("Complete Subscription!");
              window.localStorage.removeItem("sundi.payment.oid");
              navigator("/pricing");
            } else {
              alert("Failed Subscription.\nPlease contact admin");
            }
          }
        });
      } else {
        alert("user cancel");
      }
    }
    // ============================================================
  }, [lang]);

  /* api */
  const { mutate } = useMutation(() => loginCheck(), {
    onSuccess: (data) => {
      const res = data.data;
      if (res.success) {
        // 로그인 상태
        setLogin(true);
      } else {
        alert("exfired login status");
        navigator("/pricing");
      }
    },
  });

  const { data: me } = useQuery("me", {
    enabled: !!cookie.get("SU_TOKEN"),
    queryFn: () => getMyInfo(),
  });
  const hasPendingSubscription = me?.subscription_id === "PENDING";

  // 사용자 결제 완료된 상품 유/무 체크
  const userPurchasedProductCheck = () => {
    const res = subscriptionListStatus();

    res.then((data) => {
      const result = data.data;
      console.log(result);

      if (result.data.code == 200) {
        setPurchasedProductId(result.data.cid);
        setSubscriptId(result.data.pid);
        setPurchasedStatus(true);
      }
    });
  };

  // 결제 모드 확인(샌드박스, 릴리즈)
  const paymentModeCheck = () => {
    const res = options("SEL_ENV");

    res.then((data) => {
      const res = data.data;
      if (res.success) {
        setMode(res.data.ACTIVE);
      }
    });
  };

  // product purchase button Onclick Handle
  const orderOnClickHandle = (type) => {
    const accessToken = cookie.get("SU_TOKEN");

    if (accessToken) {
      if (type === "free") {
        // 무료 상품 선택의 경우 기존 구독 모두 취소 처리 및 사용자 서비스 레벨 조정
        if (isPurchased) {
          alert("test");
        }
      } else {
        // user get open paypal purchasing link
        const res = paymentGetUrl(type);

        res.then((data) => {
          const result = data.data.data;
          console.log("check. paypal purchased link");
          if (data.data.success) {
            if (
              result.payment &&
              result.payment.href &&
              result.payment.rel === "approve"
            ) {
              //
              console.log("check. complete move to purchase link");
              localStorage.setItem("sundi.payment.oid", result.oid);
              const url = result.payment.href;
              window.location.href = url;
            }
          }
        });

        return;
      }
    } else {
      alert("Please Login");
      navigator("/login");
    }
  };

  const onCreateSubscription = (planID) => async (data, actions) => {
    console.log("onCreateSubscription", me);

    if (planID === SpecialPlanID && me?.level === 1) {
      return actions.subscription.revise(me?.subscription_id, {
        plan_id: planID,
        custom_id: me.id,
      });
    }

    return actions.subscription.create({
      plan_id: planID,
      custom_id: me.id,
    });
  };

  const onApprove = async (data, actions) => {
    await approvePayment();

    setTimeout(async () => {
      alert(
        "Payment was successful. It may take approximately 5 minutes to be reflected."
      );
      window.location.href = window.location.href;
    }, 500);
  };

  //
  const productUserPurchasedStatus = (origin, compare) => {
    if (origin === compare) {
      return true;
    }
    return false;
  };

  return (
    <Container>
      <Section padding={200}>
        <LargeText bottom={30}>{Lang[lang].price.price_title}</LargeText>
        <SmallText bottom={30}>
          {Lang[lang].price.price_text1} <br />
          {Lang[lang].price.price_text2}
        </SmallText>
      </Section>

      <Section align={"row"} justify={"space-between"}>
        {me?.subscription_id === "PENDING" && (
          <PayButton
            style={{ height: "80px", pointerEvents: "none" }}
            backgroundcolor="orange"
          >
            There is a payment in progress. <br />
            It usually takes 3 to 5 minutes for your payment to be processed.
          </PayButton>
        )}
      </Section>

      <Section align={"row"} justify={"space-between"}>
        {/* free */}
        {products.map((product, idx) => {
          return (
            <Card key={idx}>
              <LargeText line_height={0.4} size={1.2}>
                {words[product].heaer1}
              </LargeText>
              <SmallText size={0.7} bottom={30}>
                {words[product].heaer2}
              </SmallText>

              <LargeText line_height={0.4} size={2.1} bottom={30}>
                {words[product].heaer3}
              </LargeText>

              {+me?.level === idx && (
                <PayButton
                  disabled={
                    productUserPurchasedStatus(0, purchaseProductId)
                      ? true
                      : false
                  }
                  backgroundcolor={
                    productUserPurchasedStatus(0, purchaseProductId)
                      ? "gray"
                      : ""
                  }
                >
                  Activated
                </PayButton>
              )}

              {product !== "free" &&
                +me?.level < idx &&
                !hasPendingSubscription && (
                  <PayPalScriptProvider options={PaypalOptions}>
                    <PayPalButtons
                      key={me?.id}
                      style={{
                        shape: "rect",
                        layout: "vertical",
                      }}
                      createSubscription={onCreateSubscription(product)}
                      onApprove={onApprove}
                    />
                  </PayPalScriptProvider>
                )}

              {/*}
              <PayButton
                onClick={() => orderOnClickHandle(cid)}
                disabled={
                  productUserPurchasedStatus(cid, purchaseProductId)
                    ? true
                    : false
                }
                backgroundcolor={
                  productUserPurchasedStatus(cid, purchaseProductId)
                    ? "gray"
                    : ""
                }
              >
                {productUserPurchasedStatus(cid, purchaseProductId)
                  ? "Cancel"
                  : "Begin"}
              </PayButton>
                */}

              {words[product].contents.map((content, cdx) => {
                return (
                  <SmallText key={cdx} size={0.7} bottom={5}>
                    <CheckIconSvg size={14} /> {content}
                  </SmallText>
                );
              })}
            </Card>
          );
        })}
      </Section>
    </Container>
  );
};

export default Pricing;

const Container = styled.div``;

const fadeInUp = keyframes`

    0% {
        opacity: 0;
        transform: translate3d(0, 5%, 0);
    }
    to {
        opacity: 1;
        transform: translateZ(0);
    }
`;

const Section = styled.div`
  animation: ${fadeInUp} 1s;

  padding-right: 5%;
  padding-left: 5%;

  ${(props) =>
    props.padding
      ? css`
          padding-top: ${props.padding}px;
        `
      : ""}

  ${(props) =>
    props.align
      ? css`
          display: flex;
          flex-direction: ${props.align};
        `
      : ""}

    ${(props) =>
    props.justify
      ? css`
          justify-content: ${props.justify};
        `
      : ""}

    @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const LargeText = styled.span`
  display: block;
  font-size: 3.75rem;
  line-height: 1;
  color: #fff;
  font-weight: bold;

  ${(props) =>
    props.bottom
      ? css`
          padding-bottom: ${props.bottom}px;
        `
      : ""}

  ${(props) =>
    props.size
      ? css`
          font-size: ${props.size}rem;
        `
      : ""}

    @media screen and (max-width: 1024px) {
    font-size: 1.25rem;
    line-height: 1.75rem;

    ${(props) =>
      props.line_height
        ? css`
            line-height: ${props.line_height}rem;
          `
        : ""}
  }
`;

const SmallText = styled.span`
  display: block;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: rgb(156 163 175);

  ${(props) =>
    props.bottom
      ? css`
          padding-bottom: ${props.bottom}px;
        `
      : ""}

  ${(props) =>
    props.size
      ? css`
          font-size: ${props.size}rem;
        `
      : ""}
`;

const Card = styled.div`
  width: calc(100% / 3 - 72px);
  margin-bottom: 30px;

  padding: 30px;
  border-radius: 5px;

  background-color: rgba(50, 50, 50, 0.4);

  @media screen and (max-width: 1024px) {
    width: 90%;
  }
`;

const PayButton = styled.button`
  width: 100%;
  height: 32px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 0.375rem;

  ${(props) =>
    props.backgroundcolor
      ? css`
          background-color: ${props.backgroundcolor};
        `
      : css`
          background-color: rgb(89 198 248);
        `}

  border: none;
  color: white;
  font-weight: 600;
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
    ${(props) =>
      props.backgroundcolor
        ? css`
            background-color: #e86363;
          `
        : css`
            background-color: rgb(112, 167, 247);
          `}
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;
