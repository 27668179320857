const CircleSvg = ({name = "circleSvg", x, y, r, viewBox = "0 0 100 100"}) => {
    return (
        <svg 
            className={name}
            xmlns="http://www.w3.org/2000/svg" 
            viewBox={viewBox}
        >
            <circle 
                className="circle"
                cx={x} 
                cy={y} 
                r={r}  
                strokeWidth="1" 
                fill="none" 
            />
        </svg>
    )
}

export default CircleSvg;