import { useNavigate } from "react-router-dom";
import { styled, css } from "styled-components";
import LogoPng from "../../../pages/assets/images/Logo.png";



const Logo = ({ widthValue }) => {

    const navigate = useNavigate();

    return (
        <LogImage 
            src={LogoPng} 
            onClick={() => navigate('/')}
            width={widthValue}
        />
    )
};

export default Logo;


const LogImage = styled.img`

    width: 100%;
    min-width: 200px;
    
    ${props =>
        props.width ?
            css`
                max-width: ${props.width}px;
            `
            :
            css`
                max-width: 281px;
            `
    }

    height: auto;
    object-fit: contain;
    transition: all .2s ease-in-out;
    
    &:hover {
        cursor: pointer;
        transform: scale(1.1) translateX(0px);
    }

    @media screen and (max-width: 1024px)  {
        width: 50%;
    }

`;