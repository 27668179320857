import { styled, css, keyframes } from "styled-components";
import Logo from "./component/atom/Logo";
import { useNavigate } from "react-router-dom";
import TopArrow from "../pages/assets/images/TopArrow";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import Language from "../recoil/Language";

const Footer = () => {
  const navigate = useNavigate();
  const [lang, setLang] = useRecoilState(Language);
  const [isVisible, setVisible] = useState("off");

  const moveToLink = (link) => {
    navigate(`/${link}`);
  };
  const translateLanguage = (type) => {
    setLang(type);
  };
  const menuOpenHandle = () => {
    isVisible == "on" ? setVisible("off") : setVisible("on");
  };

  const nowLang = () => {
    const nowLanguageString = {
      en: "English",
      ch: "中国话",
      es: "Español",
      fr: "Français",
      gm: "Deutsche",
      it: "Italiana",
      jp: "日本語",
      kr: "한국어",
    };

    return nowLanguageString[lang];
  };

  /* - */
  return (
    <Container>
      <Row width={"100%"} align={"space-between"}>
        <Row width={"80%"}>
          <Column>
            <Logo widthValue={250} />
          </Column>

          <Column>
            <Row>
              <Menu onClick={() => moveToLink("about")}>About Us</Menu>
              <Menu onClick={() => moveToLink("term")}>Term</Menu>
              <Menu onClick={() => moveToLink("digital")}>Digital</Menu>
              <Menu onClick={() => moveToLink("privacy")}>Privacy</Menu>
            </Row>

            <Row>
              <Text>
                Surroundio copyright © 2022 Nationalux Canada, All rights
                reserved.
              </Text>
            </Row>
          </Column>
        </Row>

        <Row width={"20%"} align={"end"}>
          <Column>
            <LangBox onClick={menuOpenHandle}>
              <Column type={"menu"} visible={isVisible}>
                <SelectLanguage onClick={() => translateLanguage("en")}>
                  English
                </SelectLanguage>
                <SelectLanguage onClick={() => translateLanguage("kr")}>
                  한국어
                </SelectLanguage>
                <SelectLanguage onClick={() => translateLanguage("ch")}>
                  中国话
                </SelectLanguage>
                <SelectLanguage onClick={() => translateLanguage("jp")}>
                  日本語
                </SelectLanguage>
                <SelectLanguage onClick={() => translateLanguage("fr")}>
                  Français
                </SelectLanguage>
                <SelectLanguage onClick={() => translateLanguage("gm")}>
                  Deutsche
                </SelectLanguage>
                <SelectLanguage onClick={() => translateLanguage("es")}>
                  Español
                </SelectLanguage>
                <SelectLanguage onClick={() => translateLanguage("it")}>
                  Italiana
                </SelectLanguage>
              </Column>
              <Row width={"90%"}>
                <SelectLanguage>{nowLang()}</SelectLanguage>
              </Row>
              <Row width={"10%"}>
                <TopArrow />
              </Row>
            </LangBox>
          </Column>
        </Row>
      </Row>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  width: 90%;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 30px;

  @media screen and (max-width: 1024px) {
    /* - */
  }
`;

const Row = styled.div`
  ${(props) =>
    props.width
      ? css`
          width: ${props.width};
        `
      : ""}

  ${(props) =>
    props.align
      ? css`
          justify-content: ${props.align};
        `
      : ""}
    
    display: flex;
  flex-direction: row;
  margin: 10px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;

  ${(props) =>
    props.type == "menu"
      ? css`
          visibility: ${props.visible == "on" ? "visible" : "hidden"};
          position: absolute;
          margin-bottom: 0px;
          bottom: 38px;
          right: -21px;
          width: 100%;
          border: thin solid white;
          span {
            line-height: 2rem;
          }
          span:hover {
            color: #50d0ffe6;
          }
        `
      : ""}

  @media screen and (max-width: 1024px) {
    align-items: center;
    margin-bottom: 20px;
    text-align: center;

    ${(props) =>
      props.type == "menu"
        ? css`
            bottom: 18px;
            background-color: #333;
          `
        : ""}
  }
`;

const Menu = styled.span`
  color: #ffffff;
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-right: 20px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 1024px) {
    font-size: 1rem;
    margin-bottom: 20px;
  }
`;

const Text = styled.span`
  color: #d9d7d7;
  font-size: 0.9rem;
  line-height: 1.2rem;

  @media screen and (max-width: 1024px) {
    font-size: 0.4rem;
  }
`;

const LangBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 200px;

  border: thin solid #fff;

  position: relative;

  &:hover {
    cursor: pointer;
  }
  svg {
    width: 18px;
  }
`;

const SelectLanguage = styled.span`
  color: white;
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 500;
  padding-left: 10px;
  text-align: left;
  width: 95%;

  border-bottom: thin solid white;

  &:last-child {
    border: none;
  }
`;
