import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const LoginStatus = atom({
    key: 'LoginStatus',
    default: false,
    effects_UNSTABLE: [persistAtom],
});

export default LoginStatus;