import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { styled, css } from 'styled-components';

import * as All from './Pages';
import Header from './templates/Header';
import Footer from './templates/Footer';
import TopMoveButton from './templates/component/TopMoveButton';
import CircleLoading from './templates/component/CircleLoading';
import { useRecoilState } from 'recoil';
import Language from './recoil/Language';

import Loading from './recoil/Loading';

const App = () => {

	const [headerStyle, setStyle] = useState("down");
	const [topButtonVisible, setTopButtonVisible] = useState(false);
	const [scrollTopValue, setScrollTopValue] = useState(0);
	const [isLoading, setLoading] = useRecoilState(Loading);

	/* landing scroll event for header */
	const onScrollHandle = (e) => {

		const scrollTop = document.documentElement.scrollTop;

		if (scrollTop > 0) {
			setStyle('up');
		} else {
			setStyle('down');
		}

	}

	const TopButton = () => {

		if(topButtonVisible){
			return <TopMoveButton />
		} else {
			return;
		}
		
	}

	useEffect(() => {

		let agent = navigator.userAgent.toLowerCase();

        if(agent.indexOf("kakao") > -1 || agent.indexOf('instagram') > -1) {
            if (agent.includes("kakao")){
                window.location.href = 'kakaotalk://inappbrowser/close';
              }
              // 인스타 인앱 브라우저 닫기
              else if (agent.includes("instagram")){
                window.location.href = 'instagram://inappbrowser/close';
              }

              if (navigator.userAgent.match(/iPhone|iPad/i)) { // 아이폰 접속 경우
                setLoading(0);
              }

              else { 
                window.location.href = 'intent://' + window.location.href.replace(/https?:\/\//i, '') + '#Intent;scheme=http;package=com.android.chrome;end';
              }
        }

	}, []);

	/* refresh init header style */
	useEffect(() => {

		const onScroll = e => {
			
			const scrollTop = e.target.documentElement.scrollTop;

			if(isLoading == 1) {
				document.documentElement.scrollTop = 0;
			}
			
			setScrollTopValue(scrollTop);

			if (scrollTop > 0) {
				setStyle('up');
				if(scrollTop > 90) {
					setTopButtonVisible(true);
				} else {
					setTopButtonVisible(false);
				}
			} else {
				setStyle('down');
			}
		}
		window.addEventListener('scroll', onScroll);

		return () => window.removeEventListener("scroll", onScroll);

	}, [scrollTopValue]);

	return (

		<Container
			onWheel={(e) => onScrollHandle(e)}
			overflow={isLoading}
		>
			<BrowserRouter>

				{/* <CircleLoading isLoading={isLoading}/> */}
				<Header style={headerStyle} />

				<Routes>
					<Route path="/"        element={<All.Main />} />
					<Route path="/login"   element={<All.Login />} />
					<Route path="/signup"  element={<All.SignUp />} />
					<Route path="/pricing" element={<All.Pricing />} />
					{/* <Route path="/mypage"  element={<All.Main />} /> */}
					<Route path="/about"   element={<All.About />} />
					<Route path="/term"    element={<All.Term />} />
					<Route path="/digital" element={<All.Digital />} />
					<Route path="/privacy" element={<All.Privacy />} />
					<Route path="/contact" element={<All.Contact />} />
					{/* <Route path="/payment/:pid" element={<All.Payment />} /> */}
					<Route path='*' element={<All.Main />} />
				</Routes>

				<TopButton />

				<Footer />

			</BrowserRouter>
		</Container>

	);
}

export default App;


const Container = styled.div`
	overflow-y: auto;
`;