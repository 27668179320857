import { styled } from "styled-components";
import MenuItem from "./atom/MenuItem";
import { Cookies } from "react-cookie";
import { useEffect, useState } from "react";
import LoginStatus from "../../recoil/LoginStatus";
import { useRecoilState } from "recoil";

const MenuBox = () => {

    const cookie = new Cookies();
    const [ isLogin, setLogin ] = useRecoilState(LoginStatus);

    useEffect(() => {}, [isLogin]);

    return (
        <Container align={isLogin ? "space-around" : "space-between"}>
            <MenuItem title={"Pricing"} link={"pricing"} />
            <MenuItem title={isLogin ? "Logout" : "Login"} link={isLogin ? "" : "login"} />
            { !isLogin ?
                <MenuItem title={"Sign Up"} link={"signup"} />
                :
                ""
            }
            
        </Container>
    );

};

export default MenuBox;


const Container = styled.div`
    width: 100%;
    max-width: 250px;
    display: flex;
    justify-content: ${(props) => props.align};
    align-items: center;

    @media screen and (max-width: 1024px) {
        width: 60%;
    }
    
`;