import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const User = atom({
    key: 'User',
    default: { email : "" },
    effects_UNSTABLE: [persistAtom],
});

export default User;