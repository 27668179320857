import { useEffect } from "react";
import { styled, css, keyframes } from "styled-components";

const Digital = () => {

    useEffect(() => {
        document.documentElement.scrollTop = 0;
    }, []);
    
    return (
        <Container>

            <Section>

                <LargeText bottom={80}>
                    Term of Use: Digital
                </LargeText>

                {/* 1 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Digital
                    </LargeText>

                    <SmallText>
                        - Content is for digital use within Nationalux only.
                    </SmallText>

                    <SmallText>
                        - You may not use the content as a trademark or theme song, in an audio-only capacity in which Music is the primary content, or in an international television advertising campaign with annual revenues of more than One Billion Euros.
                    </SmallText>

                    <SmallText>
                        - You may not use content in connection with adult-oriented services or ads for dating services or in connection with pornographic, defamatory, unlawful, offensive or immoral content.
                    </SmallText>

                    <SmallText>
                        - You may only use the content in campaigns and content created on Nationalux, and not with other website or content services.
                    </SmallText>

                    <SmallText>
                        These Terms of Use may be changed at any time without notice from the 'Member.' Any amendments to the terms and conditions will be posted on this page. By continuing to use 'Surroundio,' you agree to that site's amended terms and conditions.
                    </SmallText>
                </Column>
            </Section>
        </Container>
    )
};

export default Digital;

const Container = styled.div`
    
`;

const fadeInUp = keyframes`
    0% {
        opacity: 0;
        transform: translate3d(0, 1%, 0);
    }
    to {
        opacity: 1;
        transform: translateZ(0);
    }
`;  

const Section = styled.div`
    animation: ${fadeInUp} 1s;
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 200px;
    margin-top: 50px;
    margin-bottom: 100px;
`;

const LargeText = styled.h1`

    color: white;
    font-size: 3.75rem;
    line-height: 1;
    

    ${props =>
        props.size ?
            css`
                font-size: ${props.size}rem;
            `
            :
            ""
    }

    ${props =>
        props.bottom ?
            css`
                margin-bottom: ${props.bottom}px;
            `
            :
            ""
    }

    @media screen and (max-width: 1024px) {
        font-size: 1.125rem;
    }
`;

const SmallText = styled.span`

    display: block;
    color: white;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1.25rem;
    margin-bottom: 10px;

    ${props =>
        props.size ?
            css`
                font-size: ${props.size}rem;
            `
            :
            ""
    }

    @media screen and (max-width: 1024px) {
        font-size: 0.75rem;
    }
`;


const Column = styled.div`
    
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    &:hover {
        h1, span {
            color: white;
            transition: all 0.3s ease-in-out;
            transform: translateY(-3px) translateX(0px);
        }
    }
`;