export function QueryStringArray(str) {

    // suggestion remove
    let query = str.substr(1);
    let querySplit = query.split('&');

    let data = [];
    for (const item of querySplit) {
        const itemArray = item.split('=');
        data[itemArray[0]] = itemArray[1];
    }

    return data;
}