import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { styled } from "styled-components";
import LoginStatus from "../../../recoil/LoginStatus";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { logout } from "../../../api/RestApi";
import User from "../../../recoil/User";

const MenuItem = ({ title, link }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const cookie = new Cookies();

  const [, setLogin] = useRecoilState(LoginStatus);
  const [, setUser] = useRecoilState(User);

  /* api */
  const { mutate } = useMutation((accessToken) => logout(accessToken), {
    onSuccess: (data) => {
      console.log("Logout Surroundio");
    },
  });

  const moveToLink = () => {
    if (title === "Logout") {
      const accessToken = cookie.get("SU_TOKEN");
      mutate(accessToken);
      cookie.remove("SU_TOKEN");
      cookie.remove("SU_REFRESH");
      setUser([]);
      setLogin(false);

      queryClient.removeQueries();
    }

    navigate(`/${link}`);
  };

  return <Menu onClick={() => moveToLink()}>{title}</Menu>;
};

export default MenuItem;

const Menu = styled.span`
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: rgba(255, 255, 255, 1);
  font-weight: bold;

  &:hover {
    color: rgb(96 165 250);
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;
