const CheckIconSvg = ({ size = 20 }) => {
    return (
        <svg
            width={size+"px"}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="lightgray"    
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
            >
            </path>
        </svg>
    )
}

export default CheckIconSvg;