import {
    PayPalButtons,
    PayPalScriptProvider,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { useMutation } from "react-query";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";

import { options } from "../api/RestApi";

const Payment = () => {

    const { pid } = useParams();

    const [ isDev, setDev ] = useState(0);

    /* 1. 이미 구독중에 있는 상태의 유저인 경우 */
    // - 동일한 상품으로 구독이 불가하도록 처리한다.

    /* 2. 이미 구독중에 있으나 상품을 업(다운)그레이드 하는 경우 */
    // - 이전 구독 상품을 취소한다.
    // - 새롭게 신청한 구독 상품을 등록한다.

    /* api */
    const { mutate } = useMutation(
        () => options('SEL_ENV'), {
        onSuccess: (data) => {
            const res = data.data;
            if (res.success) {
                setDev(res.data.ACTIVE);
            } else {
                alert('Error. ');
                navigator('/login');
            }
        }
    }
    );

    useEffect(() => { mutate(); }, []);
    

    const paypalOtions = {
        clientId: isDev == 0 ? process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT : process.env.REACT_APP_PAYPAL_CLIENT,
        currency: "USD",
        vault: true,
        intent: "subscription",
    }


    const onApproveHandler = (data, actions) => {
        
        const orderID = data.orderID;
        const facilitatorAccessToken = data.facilitatorAccessToken;
        const subscriptionID = data.subscriptionID;

        

    }


    return (
        <Container height={window.innerHeight}>

            <ButtonSection>

                <PayPalScriptProvider
                    options={{
                        clientId: process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT,
                        currency: "USD",
                        vault: true,
                        intent: "subscription",
                    }}
                >
                    <PayPalButtons
                        createSubscription={(data, actions) => {
                            return actions.subscription
                                .create({
                                    plan_id: "P-7R452321TA760094VMSOVIUQ",
                                })
                        }}
                        onApprove={onApproveHandler}
                    />
                </PayPalScriptProvider>

            </ButtonSection>

        </Container>
    );
};

export default Payment;


const Container = styled.div`
    width: 100%;
    min-height: ${(props) => props.height}px;
    display: flex;
    justify-content: center;
    align-items: center;
`;


const ButtonSection = styled.div`
    
`