import { styled, css, keyframes } from "styled-components";
import CircleSvg from "./assets/images/CircleSvg";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";

import AnalyzeVideo from "./assets/movies/ANALYZE.mp4";
import ListenVideo from "./assets/movies/LISTEN.mp4";
import MasterVideo from "./assets/movies/MASTER.mp4";
import Master2Video from "./assets/movies/MASTER2.mp4";
import SelectVideo from "./assets/movies/SELECT.mp4";

import Lang from "../pages/lang/Lang.json";
import LoginStatus from "../recoil/LoginStatus";
import Loading from "../recoil/Loading";
import Language from "../recoil/Language";

const Main = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [sectionHeight, setSectionHeight] = useState(window.innerHeight);
  const [bottomSectionHeight, setBottomSectionHeight] = useState(0);
  const [scroll, setScroll] = useState(0);

  const bottomAlignBoxRef = useRef(null);

  const [isLogin, setLogin] = useRecoilState(LoginStatus);
  const [lang] = useRecoilState(Language);

  /* window resize : 브라우저 사이즈에 따라 유동적으로 height 값을 조정하기 위한 함수 */
  useEffect(() => {
    if (
      document.documentElement.scrollHeight >=
      bottomAlignBoxRef.current.offsetHeight + 200
    ) {
      setBottomSectionHeight(bottomAlignBoxRef.current.offsetHeight);
    }

    window.addEventListener("resize", function () {
      setWindowWidth(window.innerWidth);
    });
  }, [windowWidth, lang]);

  /* 원 버튼 클릭 시, route '/' 이동하는 함수 */
  const moveToApp = () => {
    if (isLogin) {
      window.location.href = "https://app.surroundio.org/";
    } else {
      window.location.href = "/login";
    }
  };

  // browser innerWidth에 맞추어 section height 값이 조정됨.
  const firstSectionHeight = () => {
    let height = 0;
    if (window.innerHeight > bottomSectionHeight) {
      height = window.innerHeight;
    }
    if (window.innerWidth < 450) {
      height = 640;
    } else {
      height = window.innerHeight;
    }

    return height;
  };

  return (
    <Container>
      {/* first section */}
      <Section
        background={"./images/main_bg_01.jpg"}
        height={firstSectionHeight()}
        padding_top={200}
      >
        <LargeText size={2.5} animation={"on"}>
          {Lang[lang].index.index_1} <br />
          {Lang[lang].index.index_2}
        </LargeText>
        <SmallText size={1.3} animation={"on"}>
          {Lang[lang].index.index_3} <br />
          {Lang[lang].index.index_4}
        </SmallText>

        <Area animation={"on"}>
          <Box onClick={moveToApp}>
            <CircleSvg x={50} y={50} r={60} />
            <CircleSvg
              name="circle_hidden_big"
              x={100}
              y={100}
              r={90}
              viewBox="0 0 200 200"
            />
            <Text className="main-botton">GET START</Text>
          </Box>
        </Area>
      </Section>

      {/* second section */}
      <Section padding_top={10}>
        <AlignBox height={window.innerHeight}>
          <iframe
            src="https://www.youtube.com/embed/O1pOxXYl4gY?autoplay=1&mute=1 "
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; muted;"
          ></iframe>
        </AlignBox>
      </Section>

      {/* third section */}
      <Section padding_top={200}>
        <AlignBox>
          <MiddleText>{Lang[lang].steps.steps_title}</MiddleText>
        </AlignBox>

        <AlignBox>
          <Row align={"space-around"}>
            <Column width={35}>
              <LargeText size={1.8}>{Lang[lang].steps.steps_1}</LargeText>

              <SmallText size={1.1}>{Lang[lang].steps.steps_2}</SmallText>
            </Column>

            <ImageBox width={55}>
              <VideoItem src={AnalyzeVideo} autoPlay loop muted playsInline />
            </ImageBox>
          </Row>
        </AlignBox>

        <AlignBox>
          <Row align={"space-around"}>
            <Column width={35}>
              <LargeText size={1.8}>{Lang[lang].steps.steps_3}</LargeText>

              <SmallText size={1.1}>{Lang[lang].steps.steps_4}</SmallText>
            </Column>

            <ImageBox width={55}>
              <VideoItem src={Master2Video} autoPlay loop muted playsInline />
            </ImageBox>
          </Row>
        </AlignBox>

        <AlignBox>
          <Row align={"space-around"}>
            <Column width={35}>
              <LargeText size={1.8}>{Lang[lang].steps.steps_5}</LargeText>

              <SmallText size={1.1}>{Lang[lang].steps.steps_6}</SmallText>
            </Column>

            <ImageBox width={55}>
              <VideoItem src={MasterVideo} autoPlay loop muted playsInline />
            </ImageBox>
          </Row>
        </AlignBox>

        <AlignBox>
          <Row align={"space-around"}>
            <Column width={35}>
              <LargeText size={1.8}>{Lang[lang].steps.steps_7}</LargeText>

              <SmallText size={1.1}>{Lang[lang].steps.steps_8}</SmallText>
            </Column>

            <ImageBox width={55}>
              <VideoItem src={ListenVideo} autoPlay loop muted playsInline />
            </ImageBox>
          </Row>
        </AlignBox>

        <AlignBox>
          <Row align={"space-around"}>
            <Column width={35}>
              <LargeText size={1.8}>{Lang[lang].steps.steps_9}</LargeText>

              <SmallText size={1.1}>{Lang[lang].steps.steps_10}</SmallText>
            </Column>

            <ImageBox width={55}>
              <VideoItem src={SelectVideo} autoPlay loop muted playsInline />
            </ImageBox>
          </Row>
        </AlignBox>
      </Section>

      {/* firth sectoin */}
      <Section padding_top={150}>
        <Section
          background={"https://wallpapercave.com/wp/lwfQm84.jpg"}
          height={bottomSectionHeight}
          fixed={"true"}
        />
        <BottomAlignBox ref={bottomAlignBoxRef}>
          <Column>
            <LargeText size={3.5} align={"center"} padding_top={100}>
              {Lang[lang].professional.prof_title}
            </LargeText>
            <Row align={"space-evenly"}>
              <BlackBox>
                <LargeText>{Lang[lang].professional.prof_1}</LargeText>
                <SmallText size={1}>{Lang[lang].professional.prof_2}</SmallText>
              </BlackBox>

              <BlackBox>
                <LargeText>{Lang[lang].professional.prof_3}</LargeText>
                <SmallText size={1}>{Lang[lang].professional.prof_4}</SmallText>
              </BlackBox>

              <BlackBox>
                <LargeText>{Lang[lang].professional.prof_5}</LargeText>
                <SmallText size={1}>{Lang[lang].professional.prof_6}</SmallText>
              </BlackBox>
            </Row>
          </Column>
        </BottomAlignBox>
      </Section>

      {/* fifth section */}
      <Section height={500}>
        <Area animation={"on"}>
          <Box onClick={moveToApp}>
            <CircleSvg x={50} y={50} r={60} />
            <CircleSvg
              name="circle_hidden_big"
              x={100}
              y={100}
              r={90}
              viewBox="0 0 200 200"
            />
            <Text className="main-botton">GET START</Text>
          </Box>
        </Area>
      </Section>
    </Container>
  );
};

export default Main;

/* ---------- styled-components ---------- */

const Container = styled.div`
  width: 100%;
  overflow: hidden;

  font-size: 0.6rem;
  line-height: 1.75rem;

  @media screen and (min-width: 1024px) {
    /* h1 {
            font-size: 3.75rem;
            line-height: 1;
        }

        P {
            font-size: 1.6rem;
            line-height: 1;
        } */
  }
`;

const fadeInUp = keyframes`

    0% {
        opacity: 0;
        transform: translate3d(0, 5%, 0);
    }
    to {
        opacity: 1;
        transform: translateZ(0);
    }
`;

const Section = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${(props) =>
    props.height
      ? css`
          height: ${props.height}px;
        `
      : ""}

  ${(props) =>
    props.background
      ? css`
          background-image: linear-gradient(
              rgb(35 35 35 / 70%),
              rgba(0, 0, 0, 1)
            ),
            url(${props.background});
        `
      : ""}

    background-size: cover;
  object-fit: contain;

  padding-left: 5%;
  padding-right: 5%;

  ${(props) =>
    props.padding_top
      ? css`
          padding-top: ${props.padding_top}px;
        `
      : ""}

  ${(props) =>
    props.fixed
      ? css`
          width: 100%;
          background-attachment: fixed;
          padding-left: 0%;
          padding-right: 0%;
        `
      : ""}

    @media screen and (max-width: 1024px) {
    ${(props) =>
      props.padding_top
        ? css`
            padding-top: ${props.padding_top}px;
          `
        : ""}
  }
`;

const LargeText = styled.h1`
  color: #fff;
  line-height: 1.2;

  ${(props) =>
    props.size
      ? css`
          font-size: ${props.size}rem;
        `
      : ""}

  ${(props) =>
    props.padding_top
      ? css`
          padding-top: ${props.padding_top}px;
        `
      : ""}


    ${(props) =>
    props.animation
      ? css`
          animation: ${fadeInUp} 1s;
        `
      : ""}

    @media screen and (max-width: 1024px) {
    font-size: 1.2rem;
  }
`;

const MiddleText = styled.h2`
  color: #fff;
  font-size: 3rem;
  line-height: 1;

  @media screen and (max-width: 1024px) {
    font-size: 1.2rem;
    text-align: center;
  }
`;

const SmallText = styled.p`
  line-height: 1.3;
  color: rgb(156, 163, 175);

  ${(props) =>
    props.size
      ? css`
          font-size: ${props.size}rem;
        `
      : css`
          font-size: 0.75rem;
        `}

  ${(props) =>
    props.padding
      ? css`
          padding-left: ${props.padding}px;
          padding-right: ${props.padding}px;
        `
      : ""}
    
    ${(props) =>
    props.animation
      ? css`
          animation: ${fadeInUp} 1s;
        `
      : ""}


    @media screen and (max-width: 1024px) {
    font-size: 0.75rem;
  }
`;

const Text = styled.span`
  font-size: 1.1rem;
  color: white;
`;

/* circle container */
const Box = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.3s ease-in-out;

  svg {
    filter: drop-shadow(1px 1px 5px #fff);
  }

  .circleSvg {
    overflow: initial;
  }
  .circle {
    position: absolute;
    stroke: white;
  }

  .circle_hidden_big {
    width: 200px;
    display: none;
    position: absolute;
  }

  .main-botton {
    z-index: 2;
    position: absolute;
    font-weight: bold;
  }

  &:hover {
    z-index: 3;

    cursor: pointer;
    transform: scale(1.1);

    .circleSvg > .circle {
      stroke-width: 1;
      stroke: #5599cc;
      background-color: black;
    }

    .circle_hidden_big {
      display: inline;
      transform: scale(1.4);

      .circle {
        stroke-width: 2;
        stroke: #4da4e6;
      }
    }

    .main-botton {
      transform: scale(1.1);
      color: #4da4e6;
    }
  }

  @media screen and (min-width: 1024px) {
    .circleSvg {
      width: 140px;
    }
  }

  @media screen and (max-width: 1024px) {
    .circleSvg {
      width: 120px;
    }
  }
`;

/* Box container */
const Area = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.animation
      ? css`
          animation: ${fadeInUp} 1s;
        `
      : ""}
`;

const AlignBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  iframe {
    width: 60%;
    height: ${(props) => props.height / 2}px;

    @media screen and (max-width: 1024px) {
      width: 90%;
    }
  }
`;

const BottomAlignBox = styled(AlignBox)`
  width: 90%;
  position: absolute;
  height: inherit;
  display: flex;
  flex-direction: column;
`;

const ImageBox = styled.div`
  width: ${(props) => props.width}%;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const VideoItem = styled.video`
  width: 100%;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: rotate(2deg) translateZ(0px);
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.width
      ? css`
          width: ${props.width}%;
        `
      : ""}

  @media screen and (max-width: 1024px) {
    ${(props) =>
      props.width
        ? css`
            width: 100%;
          `
        : ""}
  }
`;

const Row = styled.div`
  ${(props) =>
    props.width
      ? css`
          width: ${props.width}%;
        `
      : css`
          width: 100%;
        `}

  display: flex;
  flex-direction: row;
  margin-top: 60px;
  margin-bottom: 100px;

  ${(props) =>
    props.align
      ? css`
          justify-content: ${props.align};
        `
      : ""}

  @media screen and (max-width: 1024px) {
    flex-direction: column;

    width: 100%;

    margin-bottom: 0px;

    & > * {
      margin-top: 20px;
    }
  }
`;

const BlackBox = styled.div`
  width: calc((100% / 3) - 150px);
  height: 100%;

  padding: 40px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #00000099;
  border-radius: 5px;

  h1 {
    margin-top: 10px;
  }

  p {
    margin-top: 30px;
    line-height: 1.8;
  }

  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translateY(-10px) translateZ(0px);
  }

  @media screen and (max-width: 1024px) {
    box-sizing: border-box;
    padding: 30px;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
`;

const VideoPlayer = styled.video`
  width: 75%;
  height: 100%;

  @media screen and (max-width: 1024px) {
    width: 90%;
  }
`;
