import { css, keyframes, styled } from "styled-components";

const CircleLoading = ({isLoading = 0}) => {

    if(isLoading == 1) {
        return (
            <Container width={window.innerWidth} height={document.documentElement.scrollHeight}>
                <Background />
                <div className="lds-ripple"><div></div><div></div></div>
            </Container>
        )
    }
};

export default CircleLoading;


const loadingSpiner = keyframes`

    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
`;  

const Container = styled.div`

    position: absolute;
    width: ${(props) => props.width}px;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    .lds-ripple {
        position: relative;
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        z-index: 3;
    }

    .lds-ripple div {
        position: relative;
        position: absolute;
        border: 4px solid #fff;
        opacity: 1;
        border-radius: 50%;
        animation: ${loadingSpiner} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
        z-index: 3;
    }

    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }

`;

const Background = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff6f;
    opacity: 75%;
    z-index: 2;

`
