import { Api, AuthApi } from "./AxiosCreate";

// 회원가입
export const signup = async (user) => {
  return await Api.post("v1/signup", user);
};

// 로그인
export const login = async (user) => {
  return await Api.post("v1/login", user);
};

// 상품 리스트
export const getProudcts = async () => {
  return await Api.get("v1/products");
};

// 주문하기
export const userOrder = async (payments) => {
  return await AuthApi.post("v1/order", payments);
};

// 로그아웃
export const logout = async (accessToken) => {
  return await AuthApi.post("v1/logout", null, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

// 로그인 확인
export const loginCheck = async () => {
  return await AuthApi.post("v1/check");
};

export const getMyInfo = async () => {
  const { data } = await AuthApi.get("/v1/user/info");
  return data.data;
};

// 소셜 로그인
export const socialsLogin = async (social, token) => {
  return await Api.post(`/v1/login/${social}`, { token: token });
};

// 시스템 옵션 설정 value get
export const options = async (code = "") => {
  if (code) {
    return await Api.get(`/v1/options?code=${code}`);
  } else {
    return await Api.get(`/v1/options`);
  }
};

// get purchased link
export const paymentGetUrl = async (type) => {
  return await AuthApi.post(`/v1/payment/purchasing`, { pid: type });
};

export const approvePayment = async () => {
  return await AuthApi.post(`/v1/paypal/approve`, {});
};

// get purchased status
export const paymentStatusCheck = async (form) => {
  return await AuthApi.post("v1/payment/check", form);
};

//
export const subscriptionListStatus = async () => {
  return await AuthApi.get("v1/user/subscription");
};
