import { useEffect } from "react";
import { styled, css, keyframes } from "styled-components";


const Term = () => {

    useEffect(() => {
        document.documentElement.scrollTop = 0;
    }, []);

    return (
        <Container>

            <Section>

                <LargeText bottom={80}>
                    Term
                </LargeText>

                {/* 1 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Article 1 Purpose of Terms and Conditions
                    </LargeText>

                    <SmallText>
                        These terms and conditions apply to the rights, obligations, and responsibilities between the 'member' and the company when the 'member' uses the wired and wireless web and mobile web services and related services provided by Surroundio Co., Ltd. (from now on referred to as 'Surroundio'). The purpose is to prescribe essential matters such as terms of use and procedures.
                    </SmallText>

                    <SmallText>
                        'Surroundio' can share work among 'members' through the cloud. However, 'Surroundio' does not share the work of 'Members' with other people, companies, or organizations.
                    </SmallText>

                    <SmallText>
                        By using 'Surroundio,' 'Members' must comply with the following terms and conditions applicable to the rules of media used in 'Surroundio.' If you disagree with these terms and conditions, you may not use 'Surroundio.'
                    </SmallText>

                    <SmallText>
                        In addition, 'members' under 18 are considered to be used by their parents or guardians by agreeing to the terms and conditions.
                    </SmallText>

                    <SmallText>
                        These Terms of Use may be changed at any time without notice from the 'Member.' Any amendments to the terms and conditions will be posted on this page. By continuing to use 'Surroundio,' you agree to that site's amended terms and conditions.
                    </SmallText>
                </Column>

                {/* 2 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Article 2 Copyright
                    </LargeText>

                    <SmallText>
                        The site, graphics, visuals, design, code, algorithms, data, content, and other applications of 'Surroundio' are the sole property of 'Surroundio' and are strictly protected by the Republic of Korea and international copyright laws. 'Members' are prohibited from redistribution, copying, publishing, or using materials through acts that violate applicable copyright laws.
                    </SmallText>
                </Column>

                {/* 3 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Article 3 Privacy Policy
                    </LargeText>

                    <SmallText>
                        'Surroundio' 's privacy policy describes the information collected from 'members' and the scope of use/protection of that information. By using the 'Surroundio' site, you agree that the personal information of 'members' may be used by the 'Surroundio' privacy policy.
                    </SmallText>
                </Column>

                {/* 4 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Article 4 Members' content, rights, and approvals
                    </LargeText>

                    <SmallText>
                        By using 'Surroundio,' 'Member' is provided with a non-transferable, non-exclusive license to use the content while complying with the terms and policies of 'Surroundio.' 'Surroundio' provides the ability to modify and change existing creations. You acknowledge that the work created by the use of 'Surroundio' by the 'Member' becomes the intellectual property of the 'Member' and not the intellectual property of another person or company.
                    </SmallText>

                    <SmallText>
                        'Surroundio' does not have ownership of the work of 'members' made with the services provided by 'Surroundio,' and the intellectual property rights and copyrights of the materials belong to 'members.' Content cannot be copied, uploaded, or shared if the copyright or intellectual property rights of other rights holders other than those owned by 'members' are used without the express consent of the rights holder.
                    </SmallText>

                    <SmallText>
                        If a 'member' infringes on the copyright or intellectual property rights of others when using 'Surroundio,' 'Surroundio' shall not be liable for any damages incurred to the 'member' for the violation, and 'Surround' shall be responsible for the violation. You have the right to delete or suspend the 'member' 's account.
                    </SmallText>

                    <SmallText>
                        'Member' agrees that 'Surroundio' may host, share, and modify intellectual property of 'Member' while using 'Surround,' and 'Member' agrees that 'Surroundio' backs up the work history of 'Member' I agree to save and share. Please note that 'Surroundio' has no responsibility to ensure that works created by 'Members' comply with the present principles or principles that may arise in the future.
                    </SmallText>

                    <SmallText>
                        Your continued use of 'Surroundio' may require personal e-mail authentication or other means of authentication to maintain personal information. It is the responsibility of 'Members' to preserve the integrity of personal data and not to share account or login details with others. If other people have access to the 'Member' 's account, the 'Member' may be held responsible for the use of the 'Member' 's account. If it is determined that the 'member' 's account or login information has been obtained from another person without the consent of the 'member,' please contact 'Surroundio' and change the password as soon as possible.
                    </SmallText>

                    <SmallText>
                        A single account using 'Surroundio' may only be used as intellectual property of individuals, not groups or corporations. Using 'Surroundio' with a single account to service the intellectual property of others, groups or corporations is strictly prohibited and violates the Terms of Use.
                    </SmallText>

                    <SmallText>
                        Unless the URL address link for sharing purposes created through 'Surroundio' is posted on a site that contains pornography, obscenity, defamation, or other illegal/harmful material, you are not entitled to receive a link to the content created by 'Surroundio.' is granted. This right may also request 'Member' to stop providing links to sites that 'Surroundio' deems problematic.
                    </SmallText>

                    <SmallText>
                        If the 'Member' account does not comply with the Terms of Use, Privacy Policy, Pricing Policy, laws, or other policies referred to by 'Surroundio,' or if it is determined that 'Surroundio' threatens the confidentiality or intellectual property rights of others, 'Surroundio' reserves the right to delete, suspend, or remove works created by 'members' of It also includes cases where the account or conduct of a 'member' is liable for legal responsibility or negatively affects the services or content of others.
                    </SmallText>
                </Column>

                {/* 4 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Article 4 Members' content, rights, and approvals
                    </LargeText>

                    <SmallText>
                        By using 'Surroundio,' 'Member' is provided with a non-transferable, non-exclusive license to use the content while complying with the terms and policies of 'Surroundio.' 'Surroundio' provides the ability to modify and change existing creations. You acknowledge that the work created by the use of 'Surroundio' by the 'Member' becomes the intellectual property of the 'Member' and not the intellectual property of another person or company.
                    </SmallText>

                    <SmallText>
                        'Surroundio' does not have ownership of the work of 'members' made with the services provided by 'Surroundio,' and the intellectual property rights and copyrights of the materials belong to 'members.' Content cannot be copied, uploaded, or shared if the copyright or intellectual property rights of other rights holders other than those owned by 'members' are used without the express consent of the rights holder.
                    </SmallText>

                    <SmallText>
                        If a 'member' infringes on the copyright or intellectual property rights of others when using 'Surroundio,' 'Surroundio' shall not be liable for any damages incurred to the 'member' for the violation, and 'Surround' shall be responsible for the violation. You have the right to delete or suspend the 'member' 's account.
                    </SmallText>

                    <SmallText>
                        'Member' agrees that 'Surroundio' may host, share, and modify intellectual property of 'Member' while using 'Surround,' and 'Member' agrees that 'Surroundio' backs up the work history of 'Member' I agree to save and share. Please note that 'Surroundio' has no responsibility to ensure that works created by 'Members' comply with the present principles or principles that may arise in the future.
                    </SmallText>

                    <SmallText>
                        Your continued use of 'Surroundio' may require personal e-mail authentication or other means of authentication to maintain personal information. It is the responsibility of 'Members' to preserve the integrity of personal data and not to share account or login details with others. If other people have access to the 'Member' 's account, the 'Member' may be held responsible for the use of the 'Member' 's account. If it is determined that the 'member' 's account or login information has been obtained from another person without the consent of the 'member,' please contact 'Surroundio' and change the password as soon as possible.
                    </SmallText>

                    <SmallText>
                        A single account using 'Surroundio' may only be used as intellectual property of individuals, not groups or corporations. Using 'Surroundio' with a single account to service the intellectual property of others, groups or corporations is strictly prohibited and violates the Terms of Use.
                    </SmallText>

                    <SmallText>
                        Unless the URL address link for sharing purposes created through 'Surroundio' is posted on a site that contains pornography, obscenity, defamation, or other illegal/harmful material, you are not entitled to receive a link to the content created by 'Surroundio.' is granted. This right may also request 'Member' to stop providing links to sites that 'Surroundio' deems problematic.
                    </SmallText>

                    <SmallText>
                        If the 'Member' account does not comply with the Terms of Use, Privacy Policy, Pricing Policy, laws, or other policies referred to by 'Surroundio,' or if it is determined that 'Surroundio' threatens the confidentiality or intellectual property rights of others, 'Surroundio' reserves the right to delete, suspend, or remove works created by 'members' of It also includes cases where the account or conduct of a 'member' is liable for legal responsibility or negatively affects the services or content of others.
                    </SmallText>
                </Column>

                {/* 5 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Article 5 Distribution Approval
                    </LargeText>

                    <SmallText>
                        When a work created using 'Surroundio' is distributed for publicity or other media, we request that 'Mastered by Surroundio' be included in the video credit (not required).
                    </SmallText>
                </Column>

                {/* 6 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Article 6 Site modification and service termination
                    </LargeText>

                    <SmallText>
                        'Surround' is constantly working to improve the Site, and 'Surroundio' may change the Site at any time. We may also entirely suspend or terminate the Services and Sites. In case of suspension or termination, we will notify you in advance so that the 'member' information can be removed from the Site.
                    </SmallText>

                </Column>

                {/* 7 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Article 7 Warranty
                    </LargeText>

                    <SmallText>
                        The services of 'Surroundio,' including all information, materials, products, content, etc., provided through the website, web application, or other media are as shown, as used, and not implied to be complete.
                    </SmallText>

                    <SmallText>
                        Except for these Terms and Conditions, 'Surroundio' makes no additional specific representations or warranties concerning the products available to 'Members.' For example, 'Surroundio' has no assurance that the Site is error-free, virus-free, security-free, that the Site or service continues to operate without problems, and that the work of 'Members' is safely stored. 'Surround' excludes all warranties not expressly stated, including, but not limited to, fitness for a particular purpose, merchantability, compatibility, and non-infringement to the extent permitted by law.
                    </SmallText>

                </Column>

                {/* 8 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Article 8 Responsibility for using the Site
                    </LargeText>

                    <SmallText>
                        To the extent permitted by law, 'Surroundio' and its affiliates shall not be liable for any damages or losses of 'Members' (loss of profits or profits, operating profits, goodwill, or your use of the Site or its inability to use the Site, directly or indirectly). Loss or consequential, indirect, punitive, special, or punitive damages), and 'Member' shall not hold 'Surroundio' liable.
                    </SmallText>

                    <SmallText>
                        'Member' expressly agrees that 'Member' bears the entire risk of using 'Surroundio.' If 'Surroundio' or its affiliates are found to be liable, the total liability of 'Surroundio' to 'Members' or third parties shall be limited to the amount paid by 'Members' to use the contents of 'Surroundio.' It's possible.
                    </SmallText>

                </Column>

                {/* 9 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Article 9 Disclaimer
                    </LargeText>

                    <SmallText>
                        “Members” violate policies against “Surroundio” and its subsidiaries, agents, employees, successors, related workers, advertisers, directors, advisors, shareholders, executives, and related affiliates (collectively, “affiliates”) by using “Surroundio.” /I agree to indemnify all claims, demands, costs, liabilities, and damages (including legal expenses) arising from, including, but not limited to, infringement of intellectual property rights.
                    </SmallText>

                </Column>

                {/* 10 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Article 10 Indemnification of third parties
                    </LargeText>

                    <SmallText>
                        By using 'Surroundio,' it is understood that 'members' are not responsible for the use of third-party content while using the 'Surroundio' platform. 'Surroundio' does not permit the use of this third-party content and does not reflect the opinions of 'Surroundio.' Therefore, the use of any third-party content shared on 'Surroundio' rests solely with the 'Member' party and is not endorsed by 'Surroundio.'
                    </SmallText>
                </Column>

                {/* 11 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Article 11 tortious acts
                    </LargeText>

                    <SmallText>
                        “Surroundio” reserves the right to take appropriate action in any event, it determines to be a breach of this Agreement. This action may disclose but is not limited to information obtained from “Members” to governments or legal entities.
                    </SmallText>
                </Column>

                {/* 12 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Article 12 Payment Disclosure
                    </LargeText>

                    <SmallText>
                        By using “Surroundio” and paying for any paid “plan product” within the platform, we guarantee that the payment is made by the “member” and agree to prove that it is accurate and agree to pay any additional costs in case of price.
                    </SmallText>
                </Column>

                {/* 13 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Article 13 Payment Related
                    </LargeText>

                    <SmallText>
                        All payment information provided by “Surroundio” is accurate and complete. “Member” agrees to carry out the corresponding payment process by changing the pricing policy and payment information. Notices regarding payment price changes and discounts will be posted on the site.
                    </SmallText>
                </Column>

                {/* 14 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Article 14 Compliance with laws
                    </LargeText>

                    <SmallText>
                        By using “Surroundio,” “Members” agree to comply with “Surroundio”’s platform and regulations and laws (both domestic and overseas) governing materials, information, and content provided within the platform.
                    </SmallText>
                </Column>

                {/* 15 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Article 15 In case of legal dispute
                    </LargeText>

                    <SmallText>
                        Lawsuits filed between “Surroundio” and “Members” shall be governed by the laws of the Republic of Korea. The jurisdiction of lawsuits related to the content use contract between “Surroundio” and “members” depends on the address of the “member” at the time of the complaint. If there is no address, the jurisdiction of the district court has jurisdiction over the place of residence. If the address or residence of the “member” is not precise at the time of filing, the competent court will be determined according to the Civil Procedure Act.
                    </SmallText>

                    <SmallText>
                        Regardless of the previous, “Members” and “Surroundio” may sue for infringement of intellectual property rights or other misuse or abuse.
                    </SmallText>
                </Column>

                {/* 16 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Article 16 Governing Law
                    </LargeText>

                    <SmallText>
                        By using “Surroundio,” “Member” agrees that the terms and conditions (contract) of “Surroundio” and all disputes that may arise between “Member” and “Surroundio” shall be determined by the laws of the Republic of Korea.
                    </SmallText>
                </Column>

                {/* 17 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Article 17 Invalid Terms
                    </LargeText>

                    <SmallText>
                        Should the above terms be deemed unenforceable, invalid, or otherwise unreasonable, that term will be changed or deleted without affecting the other words.
                    </SmallText>
                </Column>

                {/* 18 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Article 18 Miscellaneous
                    </LargeText>

                    <SmallText>
                        Please note that none of these terms and conditions encourage partnerships or investment between “members” and “Surroundio” affiliated parties (company, employees, directors, shareholders, executives, affiliates, etc.).
                    </SmallText>
                </Column>

                {/* 19 */}
                <Column>
                    <LargeText size={1.5} bottom={30}>
                        Article 19 Digital
                    </LargeText>

                    <SmallText>
                        - Content is for digital use within Nationalux only.
                    </SmallText>

                    <SmallText>
                        - You may not use the content as a trademark or theme song, in an audio-only capacity in which Music is the primary content, or in an international television advertising campaign with annual revenues of more than One Billion Euros.
                    </SmallText>

                    <SmallText>
                        - You may not use content in connection with adult-oriented services or ads for dating services or in connection with pornographic, defamatory, unlawful, offensive or immoral content.
                    </SmallText>

                    <SmallText>
                        - You may only use the content in campaigns and content created on Nationalux, and not with other website or content services.
                    </SmallText>
                </Column>

            </Section>
        </Container>
    )
};

export default Term;

const Container = styled.div`
    
`;

const fadeInUp = keyframes`
    0% {
        opacity: 0;
        transform: translate3d(0, 1%, 0);
    }
    to {
        opacity: 1;
        transform: translateZ(0);
    }
`;  

const Section = styled.div`
    animation: ${fadeInUp} 1s;
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 200px;
    margin-top: 50px;
    margin-bottom: 100px;
`;

const LargeText = styled.h1`

    color: white;
    font-size: 3.75rem;
    line-height: 1;
    

    ${props =>
        props.size ?
            css`
                font-size: ${props.size}rem;
            `
            :
            ""
    }

    ${props =>
        props.bottom ?
            css`
                margin-bottom: ${props.bottom}px;
            `
            :
            ""
    }

    @media screen and (max-width: 1024px) {
        font-size: 1.125rem;
    }
`;

const SmallText = styled.span`

    display: block;
    color: rgb(156 163 175);
    font-weight: 400;
    font-size: .875rem;
    line-height: 1.25rem;
    margin-bottom: 10px;

    ${props =>
        props.size ?
            css`
                font-size: ${props.size}rem;
            `
            :
            ""
    }

    @media screen and (max-width: 1024px) {
        font-size: 0.75rem;
    }
`;


const Column = styled.div`
    
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    &:hover {
        h1, span {
            color: white;
            transition: all 0.3s ease-in-out;
            transform: translateY(-3px) translateX(0px);
        }
    }
`;