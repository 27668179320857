import React, { useEffect, useRef, useState } from "react";
import { styled, css, keyframes } from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import TwitterIconSvg from "./assets/images/TwitterIconSvg";
import GithubIconSvg from "./assets/images/GithubIconSvg";
import EmptyCheckBoxIconSvg from "./assets/images/EmptyCheckBoxIconSvg";
import CheckedBoxIconSvg from "./assets/images/CheckedBoxIconSvg";
import { signup } from "../api/RestApi";


const SignUp = () => {

    const emailWaringRef = useRef(null);
    const passwordWaringRef = useRef(null);
    const passwordConfirmWaringRef = useRef(null);
    const navigator = useNavigate();

    const [user, setUser] = useState({ email: '', password: '', confirm: '' });

    useEffect(() => {
        document.documentElement.scrollTop = 0;

    }, []);

    /* input onchange handle */
    const infoOnChangeHandle = (e, type) => {

        let ref = null;
        let input = null;

        if (type === "email") {
            input = { email: e.target.value, password: user.password, confirm: user.confirm };
            ref = emailWaringRef;
        } else if (type === "password") {
            input = { email: user.email, password: e.target.value, confirm: user.confirm };
            ref = passwordWaringRef;
        } else {
            input = { email: user.email, password: user.password, confirm: e.target.value };
            ref = passwordConfirmWaringRef;
        }

        setUser(input);
        ref.current.style.display = "none";
    }

    /* api */
    const { data, mutate } = useMutation(

        (formData) => signup(formData),
        {
            onSuccess: (data) => {
                const res = data.data;
                if (res.success) {
                    alert('Signup Success');
                    navigator('/login');
                } else {
                    if (res.msg) {
                        alert(res.msg);
                    }
                }
            }
        }
    )

    const signupOnClickHandle = () => {

        if (!user.email) {
            emailWaringRef.current.style.display = "block";
        }

        if (!user.password) {
            passwordWaringRef.current.style.display = "block";
        }

        if (!user.confirm) {
            passwordConfirmWaringRef.current.style.display = "block";
        }

        if (user.confirm === user.password) {
            passwordConfirmWaringRef.current.style.display = "block";
        }

        if (user.email && user.password && user.confirm) {
            let formData = new FormData();
            formData.append('email', user.email);
            formData.append('password', user.password);

            mutate(formData);
        }

    }

    return (

        <Container>

            <Section padding={280}>
                <LargeText>
                    Sign Up
                </LargeText>
            </Section>

            <Section
                padding_right_left={50}
                direction={"column"}
                margin_bottom={0}
            >
                {/*  */}
                <Input
                    type="text"
                    placeholder="Email"
                    onChange={(e) => infoOnChangeHandle(e, 'email')}
                />
                <WaringMessage ref={emailWaringRef}>
                    Please write down your email.
                </WaringMessage>
                {/*  */}
                <Input
                    type="password"
                    placeholder="Password"
                    onChange={(e) => infoOnChangeHandle(e, 'password')}
                />
                <WaringMessage ref={passwordWaringRef}>
                    Please write down your password.
                </WaringMessage>
                {/*  */}
                <Input
                    type="password"
                    placeholder="Confirm Password"
                    onChange={(e) => infoOnChangeHandle(e, 'confirm')}
                />
                <WaringMessage ref={passwordConfirmWaringRef}>
                    Please check your password different.
                </WaringMessage>
                {/*  */}
            </Section>

            <Section>
                <LoginButton
                    color={"rgb(89 198 248)"}
                    onClick={() => signupOnClickHandle()}
                >
                    Sign in
                </LoginButton>
            </Section>

            <Section
                padding_right_left={51}
                onClick={() => navigator('/login')}
            >
                <Line />
                <Box>
                    <Text>
                        Go back to &nbsp; <b>Login</b>
                    </Text>
                </Box>
            </Section>
        </Container>
    )

}

export default SignUp;


const Container = styled.div`
    margin-bottom: 150px;
`;

const fadeInUp = keyframes`

    0% {
        opacity: 0;
        transform: translate3d(0, 5%, 0);
    }
    to {
        opacity: 1;
        transform: translateZ(0);
    }
`;

const Section = styled.div`

    animation: ${fadeInUp} 1s;
    
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
    ${props =>
        props.direction ?
            css`
                flex-direction: ${props.direction};
            `
            :
            ""
    }

    ${props =>
        props.margin_bottom ?
            css`
                margin-bottom: ${props.margin_bottom}px;
            `
            :
            css`
                margin-bottom: 30px;
            `
    }

    ${props =>
        props.padding ?
            css`
                padding-top: ${props.padding}px;
            `
            :
            ""
    }

    ${props =>
        props.padding_right_left ?
            css`
                padding-right: ${props.padding_right_left / 2}%;
                padding-left: ${props.padding_right_left / 2}%;
            `
            :
            ""
    }

    ${props =>
        props.justify ?
            css`
                justify-content: ${props.justify};
            `
            :
            ""
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        padding-left: 5%;
        padding-right: 5%;
    }
`;

const LargeText = styled.span`

    font-size: 3.5rem;
    line-height: 1;
    color: #fff;
    font-weight: bold;

    @media screen and (max-width: 1024px) {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
`;

const SmallText = styled.span`
`;

const LoginButton = styled.button`

    display: flex;
    justify-content: center;
    align-items: center;

    width: calc(100% / 2 - 10px);
    height: 44px;
    background-color: #fff;

    ${props =>
        props.color ?
            css`
                background-color: ${props.color};
                color: white;
                font-size: .9rem;
                line-height: 1.75rem;
                font-weight: bold;
            `
            :
            ""
    }

    border: none;
    border-radius: 5px;
    padding: 5px;

    &:hover {
        cursor: pointer;
        background-color: rgb(226 232 240);
        ${props =>
        props.color ?
            css`
                        background-color: rgb(112, 167, 247);
                    `
            :
            ""
    }
    }

    svg {
        width: 16px;
    }

    @media screen and (max-width: 1024px) {
        width: 100%;
        height: 40px;
        margin-bottom: 10px;
    }
`;

const Input = styled.input`

    width: 100%;
    font-size: 1.125rem;
    line-height: 1.75rem;
    border-radius: 0.375rem;
    background-color: rgb(20, 20, 20);
    border: none;
    margin-bottom: 20px;
    padding: 1.6rem;
    color: white;
    box-sizing: border-box;

    &:hover {
        background-color: rgb(39, 39, 39);
    }

    &:focus {
        outline: 2px solid transparent;
        outline-offset: 2px;
    }

    @media screen and (max-width: 1024px) {
        height: 40px;
    }
`;

const Checked = styled.div`

    display: flex;
    align-items: center;
    padding-left: 3px;

    svg {
        width: 14px;
    }

    font-size: 12px;
    font-weight: bold;
    color: white;

    &:hover {
        cursor: pointer;
    }

`;

const WaringMessage = styled.div`
    display: none;
    color: #ff8d8d;
    font-weight: 500;
    font-size: .75rem;
    line-height: 1rem;
    width: 100%;
    padding-left: 5%;
    margin-bottom: 20px;
`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: rgb(107 114 128);
`;

const Box = styled.div`
    position: absolute;
    background-color: black;
    padding: 15px;
`;

const Text = styled.span`
    color: rgb(107 114 128);
    font-size: .75rem;

    b {
        &:hover{
            cursor: pointer;
            color: white;
        }
    }
`;