import { styled, css } from "styled-components";
import Logo from "./component/atom/Logo";
import MenuBox from "./component/MenuBox";
import { useEffect, useState } from "react";


const Header = ({style = ""}) => {
    
    return (
        <Container design={style}>
            <Logo />
            <MenuBox />
        </Container>
    );
};

export default Header;


const Container = styled.div`

    width: 90%;
    position: fixed;
    padding-left: 5%;
    padding-right: 5%;

    z-index: 6;

    display: flex;
    justify-content: space-between;
    height: 90px;

    transition: all .1s ease-in-out;

    ${props =>
        props.design == "up" ?
            css`
                background-color: rgba(80, 208, 255, 0.9);
                z-index: 999;
            `
            :
            ""
    }

    @media screen and (max-width: 1024px)  {
        padding-top: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px;
        gap: 20px;
    }
`;