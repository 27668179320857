import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const Loading = atom({
    key: 'Loading',
    default: 0,
    effects_UNSTABLE: [persistAtom],
});

export default Loading;