import axios from "axios";
import { Cookies } from "react-cookie";

const cookie = new Cookies();

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const {
      config,
      response: { status },
    } = error;
    const refreshToken = cookie.get("SU_REFRESH");

    if (status === 401 && refreshToken) {
      if (error.response.data.message === "Unauthenticated.") {
        const originRequest = config;

        try {
          const tokenReponse = await Api.post("v1/refresh", {
            refresh_token: refreshToken,
          });

          if (tokenReponse.status === 200 && !tokenReponse.data.data.error) {
            const res = tokenReponse.data.data;
            const accessToken = res.access_token;
            const refreshToken = res.refresh_token;

            const option = {
              domain: ".surroundio.org",
              httpOnly: true,
              secure: true,
              sameSite: "none",
              maxAge: 86400000,
            };

            cookie.set("SU_TOKEN", accessToken, option);
            cookie.set("SU_REFRESH", refreshToken, option);

            return axios(originRequest);
          } else {
            alert("Expired Token Please Login");
            window.location.href = "/login";
          }
        } catch (error) {
          // console.log(error);
        }
      }
    }
  }
);

export const AuthApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

AuthApi.interceptors.request.use(function (config) {
  const accessToken = cookie.get("SU_TOKEN");
  config.headers["Authorization"] = `Bearer ${accessToken}`;
  return config;
});
Api.interceptors.request.use(function (config) {
  const accessToken = cookie.get("SU_TOKEN");
  config.headers["Authorization"] = `Bearer ${accessToken}`;
  return config;
});
