import { useEffect } from "react";
import { styled, css, keyframes } from "styled-components";

import aboutImage from "./assets/images/about.jpeg";
import { useRecoilState } from "recoil";
import Lang from "../pages/lang/Lang.json";
import Language from "../recoil/Language";

const About = () => {
  const [lang] = useRecoilState(Language);

  // console.log(lang);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <Contaier>
      <Section>
        <LargeText>About Us</LargeText>

        <SmallText size={1.5}>{Lang[lang].about.about_title}</SmallText>

        <Row>
          <Column>
            <ImageBox>
              <Image src={aboutImage} />
            </ImageBox>
          </Column>

          <Column padding={5}>
            <LargeText size={1.5}>{Lang[lang].about.about_title}</LargeText>
            <SmallText size={1}>
              {Lang[lang].about.about_text1} <br />
              {Lang[lang].about.about_text2} <br />
              {Lang[lang].about.about_text3} <br />
              {Lang[lang].about.about_text4} <br />
              {Lang[lang].about.about_text5}
            </SmallText>
          </Column>
        </Row>

        <Column>
          <LargeText size={1.5}>E-Mail</LargeText>

          <SmallText>Info@Surround-Ai.Com</SmallText>
        </Column>

        <Column>
          <LargeText size={1.5}>Address</LargeText>

          <SmallText>
            4th Floor, Innovation Center, 9 Bongcheon-ro 31-gil, Gwanak-gu,
            Seoul, South Korea
          </SmallText>
        </Column>
      </Section>
    </Contaier>
  );
};

export default About;

const Contaier = styled.div`
  display: flex;
  flex-direction: row;
`;

const fadeInUp = keyframes`
    0% {
        opacity: 0;
        transform: translate3d(0, 1%, 0);
    }
    to {
        opacity: 1;
        transform: translateZ(0);
    }
`;

const Section = styled.div`
  animation: ${fadeInUp} 1s;
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 200px;
  margin-top: 50px;
  margin-bottom: 100px;
`;

const LargeText = styled.h1`
  color: white;
  font-size: 3.75rem;
  line-height: 1;
  margin-bottom: 20px;

  ${(props) =>
    props.size
      ? css`
          font-size: ${props.size}rem;
        `
      : ""}

  @media screen and (max-width: 1024px) {
    font-size: 1.125rem;
  }
`;

const SmallText = styled.span`
  color: #c8d0de;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5rem;

  ${(props) =>
    props.size
      ? css`
          font-size: ${props.size}rem;
        `
      : ""}

  @media screen and (max-width: 1024px) {
    font-size: 0.75rem;
  }
`;

const ImageBox = styled.div`
  width: 18rem;

  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-10px) translateZ(0px);
  }

  @media screen and (max-width: 1024px) {
    width: 16rem;
  }
`;

const Image = styled.img`
  width: 100%;
  border-radius: 0.375rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 30px;

  ${(props) =>
    props.padding
      ? css`
          padding-left: ${props.padding}%;
          padding-right: ${props.padding}%;
        `
      : ""}

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }
`;
