import React, { useEffect, useRef, useState } from "react";
import { styled, css, keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";

import TwitterIconSvg from "./assets/images/TwitterIconSvg";
import GithubIconSvg from "./assets/images/GithubIconSvg";
import EmptyCheckBoxIconSvg from "./assets/images/EmptyCheckBoxIconSvg";
import CheckedBoxIconSvg from "./assets/images/CheckedBoxIconSvg";

import { login, socialsLogin } from "../api/RestApi";
import { useMutation, useQueryClient } from "react-query";
import { Cookies } from "react-cookie";
import { useRecoilState } from "recoil";
import LoginStatus from "../recoil/LoginStatus";
import User from "../recoil/User";
import Loading from "../recoil/Loading";

const Login = () => {
  const Navigator = useNavigate();
  const queryClient = useQueryClient();

  const emailWaringRef = useRef(null);
  const passwordWaringRef = useRef(null);
  const cookie = new Cookies();

  const [isLogin, setLogin] = useRecoilState(LoginStatus);
  const [userInfo, setRecoilUser] = useRecoilState(User);
  const [isLoading, setLoading] = useRecoilState(Loading);

  const [user, setUser] = useState({ email: userInfo.email, password: "" });
  const [isKeep, setKeep] = useState(userInfo.email ? true : false);

  // 카카오 인앱 브라우저 내 구글 로그인 불가함으로 로그인 버튼 비활성화 처리
  const [googleLoginStatus, setGoogleLoginStatus] = useState(0);

  // console.log('t', isKeep);

  /* google, facebook initialize */
  useEffect(() => {
    let agent = navigator.userAgent.toLowerCase();

    if (agent.indexOf("kakao") > -1 || agent.indexOf("instagram") > -1) {
      if (agent.includes("kakao")) {
        window.location.href = "kakaotalk://inappbrowser/close";
      }
      // 인스타 인앱 브라우저 닫기
      else if (agent.includes("instagram")) {
        window.location.href = "instagram://inappbrowser/close";
      }

      if (navigator.userAgent.match(/iPhone|iPad/i)) {
        // 아이폰 접속 경우
        setGoogleLoginStatus(1);
      } else {
        window.location.href =
          "intent://" +
          window.location.href.replace(/https?:\/\//i, "") +
          "#Intent;scheme=http;package=com.android.chrome;end";
      }
    }

    if (cookie.get("SU_TOKEN")) {
      if (!isLogin) {
        cookie.remove("SU_TOKEN");
        cookie.remove("SU_REFRESH");
      } else {
        Navigator("/");
      }
    }

    document.documentElement.scrollTop = 0;

    function googleLogin() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: "profile email",
      });
    }

    setFBAsyncInit();
    loadSdkAsynchronously();

    gapi.load("client:auth2", googleLogin);
  }, []);

  // active
  const LoginEmailKeep = () => {
    isKeep ? setKeep(false) : setKeep(true);
  };

  const infoOnChangeHandle = (e, type) => {
    let ref = null;

    if (type === "email") {
      setUser({ email: e.target.value, password: user.password });
      ref = emailWaringRef;
    } else {
      setUser({ email: user.email, password: e.target.value });
      ref = passwordWaringRef;
    }

    ref.current.style.display = "none";
  };

  const loginOnclickHandle = () => {
    if (!user.email) {
      emailWaringRef.current.style.display = "block";
    }

    if (!user.password) {
      passwordWaringRef.current.style.display = "block";
    }

    if (user.email && user.password) {
      setLoading(1);
      let formData = new FormData();
      formData.append("email", user.email);
      formData.append("password", user.password);
      mutate(formData);
    }
  };

  /* socials */
  const googleSocialLogin = useGoogleLogin({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    scope: "profile email",
    onSuccess: async (res) => {
      const token = res.tokenId;
      socialsRestApi("google", token);
    },
    onFailure: (err) => {
      setLoading(0);
      console.log("------------");
    },
  });

  const { signIn } = googleSocialLogin;

  // facebook login
  const facebookSocialLogin = () => {
    window.FB.getLoginStatus((response) => {
      facebookLoginStatusCheck(response);
    });
  };

  // login onclick handle
  const socialsLoginOnClickHandle = (type) => {
    setLoading(1);
    if (type === "google") {
      if (googleLoginStatus == 1) {
        setLoading(0);
        alert(
          "카카오톡 인앱 브라우저에서는 구글 로그인 서비스가 불가능합니다."
        );
      } else {
        signIn();
      }
    } else if (type === "facebook") {
      setLoading(0);
      alert("Comming soon!");
      // facebookSocialLogin();
    }
  };

  // facebook status login check
  const facebookLoginStatusCheck = (response) => {
    if (response.status === "connected") {
      socialsRestApi("facebook", response.authResponse.accessToken);
    } else {
      window.FB.login((response) => {
        if (response.authResponse) {
          socialsRestApi("facebook", response.authResponse.accessToken);
        }
      });
    }

    setLoading(0);
  };

  // 로그인을 성공한 경우 호출되는 Server API
  const socialsRestApi = (type, accessToken) => {
    const data = socialsLogin(type, accessToken);
    data.then((res) => {
      const response = res.data.data;
      const level = response.level;
      localStorage.setItem("SU_U_LEVEL", level);
      accessTokenCookie(
        response.token.access_token,
        response.token.refresh_token
      );
      window.location.href = "/";
    });
  };

  const setFBAsyncInit = () => {
    window.fbAsyncInit = () => {
      window.FB.init({
        version: `v17.0`,
        appId: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
        xfbml: true,
        cookie: true,
      });
    };
  };

  const loadSdkAsynchronously = () => {
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  };

  const accessTokenCookie = (accessToken, refreshToken) => {
    var myDate = new Date();
    myDate.setMonth(myDate.getMonth() + 1);

    document.cookie = "SU_TOKEN=" + accessToken + ";domain=.surroundio.org";
    document.cookie = "SU_REFRESH=" + refreshToken + ";domain=.surroundio.org";

    cookie.set("SU_TOKEN", accessToken);
    cookie.set("SU_REFRESH", refreshToken);

    setLogin(true);
    setLoading(0);
  };

  /* email/password login api */
  const { mutate } = useMutation((formData) => login(formData), {
    onSuccess: (data) => {
      const res = data.data;
      if (res.success) {
        var myDate = new Date();
        myDate.setMonth(myDate.getMonth() + 1);

        document.cookie =
          "SU_TOKEN=" +
          res.data.token.access_token +
          ";domain=.surroundio.org;expires=" +
          myDate +
          ";";
        document.cookie =
          "SU_REFRESH=" +
          res.data.token.refresh_token +
          ";domain=.surroundio.org";

        cookie.set("SU_TOKEN", res.data.token.access_token);
        cookie.set("SU_REFRESH", res.data.token.refresh_token);

        setLogin(true);

        if (isKeep) {
          setRecoilUser({ email: user.email });
        } else {
          setRecoilUser({ email: "" });
        }

        queryClient.resetQueries();

        Navigator("/");
      } else {
        if (res.msg) {
          alert(res.msg);
        }
      }

      setLoading(0);
    },
  });

  return (
    <Container>
      <Section padding={200}>
        <LargeText>Login</LargeText>
      </Section>

      <Section justify={"space-between"} padding_right_left={50}>
        <LoginButton
          onClick={() => socialsLoginOnClickHandle("google")}
          color={googleLoginStatus == 1 ? "gray" : ""}
        >
          <TwitterIconSvg /> &nbsp; Enter With Google
        </LoginButton>

        <LoginButton
          onClick={() => socialsLoginOnClickHandle("facebook")}
          color="gray"
        >
          <GithubIconSvg /> &nbsp; Enter With FaceBook
        </LoginButton>
      </Section>

      <Section padding_right_left={50} direction={"column"} margin_bottom={0}>
        <Input
          type="text"
          placeholder="Email"
          value={user.email}
          onChange={(e) => infoOnChangeHandle(e, "email")}
        />
        <WaringMessage ref={emailWaringRef}>
          Please write down your email.
        </WaringMessage>
        <Input
          type="password"
          placeholder="Password"
          onChange={(e) => infoOnChangeHandle(e, "password")}
        />
        <WaringMessage ref={passwordWaringRef}>
          Please write down your password.
        </WaringMessage>
      </Section>

      <Section padding_right_left={50} justify={"unset"}>
        <Checked onClick={LoginEmailKeep}>
          {!isKeep ? <EmptyCheckBoxIconSvg /> : ""}
          {isKeep ? <CheckedBoxIconSvg /> : ""}
          &nbsp; &nbsp; Keep me signed in
        </Checked>
      </Section>

      <Section>
        <LoginButton color={"rgb(89 198 248)"} onClick={loginOnclickHandle}>
          Sign in
        </LoginButton>
      </Section>

      <Section padding_right_left={51}>
        <Line />
        <Box>
          <Text>
            No Account? &nbsp;{" "}
            <b onClick={() => Navigator("/signup")}>Sign Up</b>
          </Text>
        </Box>
      </Section>
    </Container>
  );
};

export default Login;

const Container = styled.div`
  margin-bottom: 150px;
`;

const fadeInUp = keyframes`

    0% {
        opacity: 0;
        transform: translate3d(0, 5%, 0);
    }
    to {
        opacity: 1;
        transform: translateZ(0);
    }
`;

const Section = styled.div`
  animation: ${fadeInUp} 1s;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.direction
      ? css`
          flex-direction: ${props.direction};
        `
      : ""}

  ${(props) =>
    props.margin_bottom
      ? css`
          margin-bottom: ${props.margin_bottom}px;
        `
      : css`
          margin-bottom: 30px;
        `}

    ${(props) =>
    props.padding
      ? css`
          padding-top: ${props.padding}px;
        `
      : ""}

    ${(props) =>
    props.padding_right_left
      ? css`
          padding-right: ${props.padding_right_left / 2}%;
          padding-left: ${props.padding_right_left / 2}%;
        `
      : ""}

    ${(props) =>
    props.justify
      ? css`
          justify-content: ${props.justify};
        `
      : ""}

    @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
  }
`;

const LargeText = styled.span`
  font-size: 3.5rem;
  line-height: 1;
  color: #fff;
  font-weight: bold;

  @media screen and (max-width: 1024px) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`;

const SmallText = styled.span``;

const LoginButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: calc(100% / 2 - 10px);
  height: 44px;
  background-color: #fff;

  ${(props) =>
    props.color
      ? css`
          background-color: ${props.color};
          color: white;
          font-size: 0.9rem;
          line-height: 1.75rem;
          font-weight: bold;
        `
      : ""}

  border: none;
  border-radius: 5px;
  padding: 5px;

  &:hover {
    cursor: pointer;
    background-color: rgb(226 232 240);
    ${(props) =>
      props.color
        ? css`
            background-color: rgb(112, 167, 247);
          `
        : ""}
  }

  svg {
    width: 16px;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
  }
`;

const Input = styled.input`
  width: 100%;
  font-size: 1.125rem;
  line-height: 1.75rem;
  border-radius: 0.375rem;
  background-color: rgb(20, 20, 20);
  border: none;
  margin-bottom: 20px;
  padding: 1.6rem;
  color: white;
  box-sizing: border-box;

  &:hover {
    background-color: rgb(39, 39, 39);
  }

  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }

  @media screen and (max-width: 1024px) {
    height: 40px;
  }
`;

const Checked = styled.div`
  display: flex;
  align-items: center;
  padding-left: 3px;

  svg {
    width: 14px;
  }

  font-size: 12px;
  font-weight: bold;
  color: white;

  &:hover {
    cursor: pointer;
  }
`;

const WaringMessage = styled.div`
  display: none;
  color: #ff8d8d;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  width: 100%;
  padding-left: 5%;
  margin-bottom: 20px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgb(107 114 128);
`;

const Box = styled.div`
  position: absolute;
  background-color: black;
  padding: 15px;
`;

const Text = styled.span`
  color: rgb(107 114 128);
  font-size: 0.75rem;

  b {
    &:hover {
      cursor: pointer;
      color: white;
    }
  }
`;
